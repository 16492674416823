.loginPageContent {
    width:100%;
    overflow: hidden;
    height: 100vh;
    /* background: #514f4f 0% 0% no-repeat padding-box; */
    background-image: linear-gradient(rgba(3, 3, 3, 0.5), rgba(10, 0, 0, 0.5)), url("https://leaddetails.s3.amazonaws.com/Mask+Group+6.svg");
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: realtive;
    display: flex;
    align-items: center;
    /* opacity: .66;             */

}

.eye {
    position: absolute;
    top: -35px;
    right: 12px;
}


.loginSubmitBtn {
    background-color: #FA9001;
    color: white;
    /* width: 50%; */
    font: normal normal medium 24px/35px 'Poppins',sans-serif;
    letter-spacing: 0px;
}

.signup p{
    color: #272727;  
    margin-bottom: 0px;
    
}
p.account{
    margin-bottom: 0px;
}

.forgotLink{
    float: right;
    margin-top: -20px;
    font: normal normal medium 20px/30px Poppins;
    letter-spacing: 0px;
}

.logoimg{
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    padding-top: 2%;
}
 .card{
    border: none;
 }
 .card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.signup:hover{
    color: black;
}
/* @media only screen and (max-width:580px){
    .btn1{
        margin-left: 46%
    
    }
} */
