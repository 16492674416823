.menu-display {
  width: 50px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  top: 10px;
}
.nav-link:focus {
  color: #fa9001;
}
.hide-menu {
  visibility: hidden;
}

.menu-item {
  cursor: pointer;
  padding: 6px;
}

.blue-theme {
  color: #fa9001 !important;
}

@media (max-width: 767px) {
  .hide-menu {
    display: block;
    visibility: visible;
  }
}
.navbar-brand {
  font: normal normal medium 24px/35px;
}
